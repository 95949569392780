html {
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

.ct--bottom {
  display: none !important; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex: 0 1 auto; }

body {
  font-family: -apple-system, Arial !important;
  font-size: 14px !important;
  line-height: 1.43 !important;
  color: #666 !important;
  margin: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f7 !important;
  overflow-x: hidden;
  overflow: overlay; }

@-moz-document url-prefix() {
  body {
    overflow: auto; } }
  body.ReactModal__Body--open {
    overflow: hidden; }
  body.overlay-body {
    overflow: overlay; }

@-moz-document url-prefix() {
  body.overlay-body {
    overflow: auto; } }
  body.hide-scroll {
    overflow: hidden; }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    body {
      font-family: -apple-system, Arial !important;
      font-size: 14px !important;
      line-height: 1.43 !important;
      color: #666 !important;
      margin: 0 !important; } } }

.Toastify__toast-container {
  z-index: 2147483647 !important; }

.Toastify__toast {
  border-radius: 0.5rem !important; }

.Toastify__toast-body {
  padding-right: 30px !important;
  min-width: 220px !important; }

/* force toast to stay on the center in mobile breakpoints */
/* Force toast to stay at the center on mobile breakpoints */
@media only screen and (max-width: 767px) {
  .Toastify__toast-container {
    position: fixed !important;
    top: 5% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    max-width: 90% !important;
    text-align: center !important; } }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

input,
textarea,
select,
button,
a {
  font-family: inherit;
  font-size: 100%; }

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 25px;
  margin-bottom: 10px;
  color: #191b1f; }

thead {
  color: #191b1f; }

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0; }

[tabindex="-1"]:focus {
  outline: 0; }

button {
  padding: 0;
  background-color: transparent;
  border: 0; }

.border-bottom {
  border-width: 1px;
  border-style: solid; }

#app {
  height: 100%; }

.flex {
  display: flex; }
  .flex.flex-direction-column {
    flex-direction: column; }
  .flex.flex-direction-row {
    flex-direction: row; }
  .flex.flex-align-center {
    align-items: center; }
  .flex.flex-justify-space-between {
    justify-content: space-between; }
  .flex.flex-justify-space-evenly {
    justify-content: space-evenly; }
  .flex.flex-justify-flex-start {
    justify-content: flex-start; }
  .flex.flex-justify-flex-end {
    justify-content: flex-end; }
  .flex.flex-justify-center {
    justify-content: center; }

.flex-w-wrap {
  flex-wrap: wrap; }

/* Comprehensive mobile interaction fix */
a,
button,
[onclick],
[role="button"],
input,
select,
textarea {
  /* More aggressive touch delay removal */
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  /* Enhanced touch responsiveness */
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  /* Prevent default behaviors that might interfere */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
